.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  /* background-color: black; */

}


h1 {
  margin: 20px 10px;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.5;
  /* color: white; */
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1.5s fadeInUp;
}

@keyframes fadeOut {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }



  100% {
    transform: translateY(0%);
    /* display: none; */
    height: 0;
    opacity: 0;
  }
}


.fadeOutUp-animation {
  animation: 4s fadeOut forwards;
}

@keyframes iconAnimation {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  50% {
    transform: translateY(-20%);
    opacity: 1;
  }

  100% {
    transform: translateY(15%);
    opacity: 1;
  }
}

.icon-animation {
  animation: 1.5s iconAnimation forwards;
}

@keyframes searchIconAnimation {

  0%,
  30%,
  60%,
  90% {
    transform: translateY(-5%);

  }

  10%,
  40%,
  70%,
  100% {
    transform: translateY(15%);
  }

  20%,
  50%,
  80% {
    transform: translateY(35%);

  }

}

.searching-icon-animation {
  animation: 8s searchIconAnimation forwards;
  transform: translateY(15%);
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}


body {
  padding: 5px;
  background: #eae83a;
}

.btn,
a.btn,
button.btn {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}


a.btn-border-shadow {
  padding: calc(1.5rem - 12px) 3rem 1.5rem;
  background: #fff;
}


a.btn-border-shadow:before {
  position: absolute;
  top: -6px;
  left: -6px;

  width: 100%;
  height: 100%;

  content: "";
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  border: 3px solid #000;
  border-radius: 0.5rem;
}

a.btn-border-shadow:hover {
  padding: calc(1.5rem - 6px) 3rem;
}

a.btn-border-shadow:hover:before {
  top: 0;
  left: 0;
}

.category-btn {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  max-width: 100%;
  position: relative;
  display: inline-block;
  padding: 0.1rem 0.7rem;
  margin: 0.2rem;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
  background: #fff;
  border: 3px solid #000;
  border-radius: 0.5rem;
}

img,
iframe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  border: 3px solid #000;
  border-radius: 0.5rem;
}


.img-container-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

.not-geo-message {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 0.2rem 0.4rem;
  margin: 0.2rem;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
  background: #fff;
  border: 3px solid #000;
  border-radius: 0.5rem;
  padding: calc(1.5rem - 12px) 3rem 1.5rem;
  background: #fff;
}

h3 {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.5;
  color: black;
}

h4 {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: black;
}

.place-container {
  border: 3px solid #000;
  border-radius: 0.5rem;
  background: #fff;
  padding: calc(1.5rem - 12px) 3rem 1.5rem;
  margin: 0.5rem;
}

.place-container-outer,
.tag-container-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}






/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: #eae83a;
}


/* selected link */
a:active {
  color: black;
}

.buttontext {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.collapse-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  flex-direction: row;
}